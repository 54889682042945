import React from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'

import { Map, Marker, Popup, TileLayer, Tooltip } from 'react-leaflet'
import {codeIntoCoord} from '../utils/coordinates'
import { Grid, Header, Accordion,Icon, Button, Segment, Dimmer,Loader, Modal,Image } from 'semantic-ui-react';
import mapStyles from './Map/MapDisplay/mapStyles.json'

const linkStyles = mapStyles['linkStyles']

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
         this.state = {
            userData:[],
            error:'',
            isLoading:true,
            mapCord:[10,10],
            zoom:2,
            activeIndex: 0
        };
        this.viewOnMap = this.viewOnMap.bind(this)
        this.deleteAddress = this.deleteAddress.bind(this)
       }
       componentDidMount(){
        axios.post('/api/address/view',{'api':'address'})
        .then(res=>{
          if(res.data !== 'No documents found'){
            this.setState({userData:res.data,isLoading:false})
          }
          else{
            this.setState({userData:[],isLoading:false})
          }
        })     
        .catch(err =>this.setState({error:'Error while fetching data'}))
       }
       viewOnMap(code){
        const getCoords = codeIntoCoord(code)
        const newLat = getCoords[0]
        const newLng = getCoords[1]
        this.setState({mapCord:[newLat,newLng],zoom:15})
      }
      getCoordinates(code){
        const getCoords = codeIntoCoord(code)
        const newLat = getCoords[0]
        const newLng = getCoords[1]
        return [newLat,newLng]
      }
    
    changeCoord(code){
        const getCoords = codeIntoCoord(code)
        const newLat = getCoords[0]
        const newLng = getCoords[1]
        return [newLat, newLng]
    }
    getAddressCount(){
      return this.state.userData.length 
    }
    handleClick = (e, titleProps) => {
      const { index } = titleProps
      const { activeIndex } = this.state
      const newIndex = activeIndex === index ? -1 : index
    
      this.setState({ activeIndex: newIndex })
    }
    deleteAddress(address){
      axios
        .post('/api/address/delete',
          {
            'address':address
          })
        .then(res=>{
          this.setState({isLoading:true})
          axios.post('/api/address/view')
          .then(res=>{
            if(res.data !== 'No documents found'){
              this.setState({userData:res.data,isLoading:false})
            }
            else{
              this.setState({userData:[],isLoading:false})
            }
          })     
          .catch(err =>this.setState({error:'Error while fetching data'}))
          })
        .catch(err=>this.setState({error:'Error while deleting data'}))
    }
    render() {
        const {userData, error, isLoading, mapCord, zoom, activeIndex} = this.state
        if(isLoading){
            return(
            <Segment style={{marginTop:'50px'}}>
                <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
                </Dimmer>
            </Segment>
            )
        }
        return (
            <div>
            <Grid style={{marginTop:'100px'}} columns={2} stackable>          
                <Grid.Column width={10}>
                <Segment>
                <div className="showmap">                    
                    <Map 
                    center={mapCord}
                    zoom={[zoom]}
                    >
                        <TileLayer
                                attribution='&copy; OpenStreetMap'
                                url={linkStyles['street']}
                            />
                        {userData.map((data,index)=>
                            <Marker
                            position={this.changeCoord(data.code)}
                            key={
                            index}
                            >
                                <Popup>{data.name}<br />{data.extra}</Popup>
                                <Tooltip>{data.name}</Tooltip>
                            </Marker>
                        )}
                    </Map>
                </div>
                <br/><p>{error}</p>
                </Segment>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header as='h4'>Registered Address</Header>
                  <Accordion styled>
                  {userData.map((dat,index)=>
                  <div key={index}>
                    <Accordion.Title active={activeIndex === index} index={index} onClick={this.handleClick}>
                      <Icon name='dropdown' />
                      {dat.name}
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === index}>
                      <Header as='h2'>Unique Code: {dat.code}</Header>
                      <p>{dat.extra}</p>
                      <Grid>
                      <Grid.Row>
                        <Grid.Column width={4}>
                          <Button basic color='blue' onClick={()=>this.viewOnMap(dat.code)}>View</Button>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Modal trigger={<Button basic color='yellow'>
                                Share
                            </Button>}>
                                <Modal.Header>Share the Address</Modal.Header>
                                <Modal.Content image>
                                <Image 
                                    wrapped 
                                    size='medium' 
                                    src={"https://api.qrserver.com/v1/create-qr-code/?color=000&bgcolor=FFFFFF&data=https://www.google.com/maps?daddr="+this.getCoordinates(dat.code)+"&qzone=1&margin=0&size=150x150&ecc=L"} />
                                <Modal.Description>
                                    <Header>Unique Code:{dat.code}</Header>
                                    <p><a href={"https://www.google.com/maps?daddr="+this.getCoordinates(dat.code)} target='_blank' without="true" rel="noopener noreferrer"><Icon name='location arrow'></Icon>Get Direction</a></p>
                                    <p><a href={"https://wa.me/?text=Hey My Address Code is "+dat.code+". Check the google map:https://www.google.com/maps?daddr="+this.getCoordinates(dat.code)} target='_blank' without="true" rel="noopener noreferrer">Share On<Icon name='whatsapp' size='big' color='green'></Icon></a></p>
                                    <p><a href={"https://www.facebook.com/sharer/sharer.php?u=www.thegana.com/code="+dat.code} target='_blank' without="true" rel="noopener noreferrer">Share On<Icon name='facebook' size='big' color='blue'></Icon></a></p>
                                </Modal.Description>
                                </Modal.Content>
                            </Modal>
                        </Grid.Column>
                        <Grid.Column width={4}>
                          <Link to={{ pathname:'/edit',state:{name:dat.name} }}><Button basic color='green' >Edit</Button></Link>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Modal trigger={<Button basic color='red'>
                                Delete
                            </Button>}>
                                <Modal.Header>Are you sure you want to delete this address?</Modal.Header>
                                <Modal.Content>
                                <Modal.Description>
                                    <Header>16 digit Code:{dat.code}</Header>
                                    <Header>Unique Name:{dat.name}</Header>
                                    <Button basic color='blue' onClick={()=>this.deleteAddress(dat.name)}>Delete</Button>
                                </Modal.Description>
                                </Modal.Content>
                            </Modal>
                        </Grid.Column>
                      </Grid.Row>
                      </Grid>
                    </Accordion.Content>
                    </div>
                    )}
                    <Accordion.Title>
                      {userData && userData.length < 5 ?
                        <p>
                          <Icon name='add circle' />
                          <Link to='/map'>Add New Address</Link>
                        </p>
                      :
                        <p><Icon name='hourglass full' loading></Icon> 5 / 5 Address used </p>
                      }
                    </Accordion.Title>
                  </Accordion>
    
                </Grid.Column>
            </Grid>
            </div>
          );
        }
      
}