import React from 'react'
import {Form, Button, Header, Container, Segment, Dimmer, Loader, Message, Icon} from 'semantic-ui-react'
import { Link } from "react-router-dom";
import axios from 'axios'
import './style.css'

export default class ChangePassword extends React.Component{
    constructor(props){
        super(props)
        this.state= {
            email:'',
            token:'',
            password:'',
            password2:'',
            errors:{},
            isLoading:true,
            verified:false
        }
        this.checkPass = this.checkPass.bind(this)
    }
    handleInputChange = (event) =>{
        const {value, name} = event.target
        this.setState({[name]:value})
    }
    componentDidMount() {
        const { match: { params } } = this.props;
        axios.post('/api/users/verify/reset',{
            'email':params.email,
            'token':params.token
        })
        .then((res)=>{
            this.setState({
                email:params.email,
                token:params.token,
                verified:true,
                isLoading:false
            })
        })
        .catch((err) => {
            this.setState({
                err:err,
                isLoading:false
            })
        })
    }
    onSubmit = (event) =>{
        this.setState({isLoading:true})
        event.preventDefault()
        if(this.checkPass()){
            axios.post('/api/users/reset/password/new',{
                'email':this.state.email,
                'password':this.state.password
            })
            .then(res=>{
                this.setState({isLoading:false})
                if(res.status === 200){
                    this.props.history.push('/login')
                } else{
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .catch(err => {
               this.setState({errors:err.response.data, isLoading:false})
            });
        }
    }

    checkPass() {
        if(this.state.password !== this.state.password2){
            this.setState({errors:{'password2':'Password didn\'t match.'}, isLoading:false})
            return false
        }
        if(this.state.password.length < 8){
            this.setState({errors:{'password':'Password must be atleast 8 letters'}, isLoading:false})
            return false
        }
        return true
    }

    render(){
        const {errors, isLoading, verified, password, password2} = this.state
        if(isLoading){
            return(
            <Segment style={{marginTop:'60px'}}>
                <Dimmer active inverted>
                <Loader inverted>Loading...</Loader>
                </Dimmer>
            </Segment>
            )
        }
        return(
            <div className="background" >
                <Container text>
                    <Segment.Group className="spaceabove">
                        <Segment>
                            <Header as='h3'>Reset your password</Header>
                        </Segment>
                        <Segment>
                            {verified ?
                                <div>
                                    <Message>
                                        <Message.Header>Token Verified <Icon circular inverted name='checkmark' color='green' /></Message.Header>
                                    </Message>
                                    
                                    <Form onSubmit={this.onSubmit}>
                                        <Form.Field>
                                            <label>New Password</label>
                                            <input 
                                                type="password"
                                                name="password"
                                                placeholder="Enter password"
                                                value={password}
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                            <span style={{color:"red"}}>{errors.password}</span>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Confirm Password</label>
                                            <input 
                                                type="password"
                                                name="password2"
                                                placeholder="Enter password again"
                                                value={password2}
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                            <span style={{color:"red"}}>{errors.password2}</span>
                                        </Form.Field>
                                        <Button basic type='submit' color='green'>Change Password</Button>
                                    </Form>
                                </div>
                            :
                                <Message negative>
                                    <Message.Header>We're sorry we can't reset the password.</Message.Header>
                                    <p>The token has expired</p>
                                </Message>
                            }
                        </Segment>
                        <Segment>                            
                            Back to <Link to='/login' color='blue'>Login</Link>                            
                        </Segment>
                    </Segment.Group>
                </Container>
            </div>
        )
    }
}