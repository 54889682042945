import React from 'react'
import { Header, Container, Message, Segment } from 'semantic-ui-react';
import Axios from 'axios';
import './style.css'

export default class EmailVerify extends React.Component {
    state = {
        email: '',
        token:'',
        verified:false,
        err:{}
    }
    componentDidMount(){
        const { match: { params } } = this.props;
        Axios.post('/api/users/verify',{
            'email':params.email,
            'token':params.token
        })
        .then((res)=>{
            this.setState({
                email:params.email,
                token:params.token,
                verified:true
            })
        })
        .catch((err) => {
            this.setState({
                email:params.email,
                token:params.token,
                err:err
            })
        })
    }
    render () {
        const {email,token,verified, err} = this.state
        return (
            <div className="background">
                <Container>
                    <Segment.Group className="spaceabove">
                    <Header as="h1">Email Verification Page</Header>
                    <p>Email:{email}</p>
                    <p>Token:{token}</p>
                    {verified ? 
                        <Message
                            success
                            header='Your email verification was successful'
                            content='You may now log-in with the email address you have chosen'
                        /> :
                        <Message negative>
                            <Message.Header>Sorry but verfication process failed. The token may have been expired.</Message.Header>
                            <p>{err.error}</p>
                        </Message>
                    }
                    </Segment.Group>
                </Container>
            </div>
            
        )
    }
}