import React from 'react'
import { Container, Icon, Button } from 'semantic-ui-react'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import './mapDisplay.css'
import  mapStylelink  from "./mapStyles.json"
const linkStyles = mapStylelink['linkStyles']

export default class MapDisplay extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            error:{},
            zoom:10
        };
        this._onClickMap = this._onClickMap.bind(this)
        this.currentLocation = this.currentLocation.bind(this)
        this.getZoom = this.getZoom.bind(this)
    }
    _onClickMap(map, evt) {
        const lng = map.latlng.lng.toFixed(6)
        const lat = map.latlng.lat.toFixed(6)
        this.props.onMapClickChange(lat,lng)
    }
    getZoom(map,evt){
        this.setState({zoom:map._zoom})
    }

    
    currentLocation(){
        navigator.geolocation.getCurrentPosition(
            (coords) => {
              const { longitude, latitude } = coords.coords;
              this.props.onMapClickChange(latitude,longitude)
            },
            err => {
                this.setState({
                    error:{'geo':'GeoLocation Permission was denied!!'}
                })
            }
      );
    }

    render(){
        const { error,zoom} = this.state
        const lat = this.props.lat
        const lng = this.props.lng
        const mapStyle = linkStyles[this.props.style]
        return(
            <Container fluid>                
                <p style={{color:'red'}}>{error.geo}</p>
                
                    <div className="fill">
                        <Map
                            center={[lat,lng]}
                            zoom={zoom}
                            onClick={this._onClickMap}
                            onZoom={this.getZoom}
                            >
                            <Button size='large' icon id="refreshButton" onClick={this.currentLocation}>
                                <Icon name="crosshairs" size='big'/>
                            </Button>
                            <TileLayer
                                attribution='&copy; OpenStreetMap'
                                url={mapStyle}
                            />
                            <Marker 
                                position={[lat,lng]}
                            >
                                <Popup>{lat},{lng}</Popup>
                            </Marker>
                        </Map>
                        </div>
                            
            </Container>
        )
    }
}