import React from 'react'
import { Item, Header, Button, Grid, Image} from 'semantic-ui-react'
import './mapLayout.css'
import satellite from './satellite.png'
import street from './street.png'
import local from './local.PNG'

export default class MapLayout extends React.Component {
    constructor(props){
        super(props)
        this.changeLayout = this.changeLayout.bind(this)
    }
    changeLayout(layout){
        this.props.changeLayout(layout)
    }
    render(){
        return(
            <div>
                <Header as='h4'>Map Layer</Header>
                <Grid divided='vertically'>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <Item>
                            <Button basic circular onClick={()=>this.changeLayout('satellite')}><Image src={satellite} size='large' circular /></Button>
                            <Header as="h3" textAlign='center'>Satellite</Header>
                            </Item>
                        </Grid.Column>
                        <Grid.Column>
                            <Item>
                            <Button basic circular onClick={()=>this.changeLayout('street')}><Image src={street} size='large' circular /></Button>
                            <Header as="h3" textAlign='center'>Roadmap</Header>
                            </Item>
                        </Grid.Column>
                        <Grid.Column>
                            <Item>
                            <Button basic circular onClick={()=>this.changeLayout('basic')}><Image src={local} size='large' circular /></Button>
                            <Header as="h3" textAlign='center'>Local</Header>
                            </Item>
                        </Grid.Column>                
                    </Grid.Row>
                </Grid>
            </div>     
        )
    }
}