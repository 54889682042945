import React from 'react'
import axios from 'axios'
import {Grid, Form, Button, Segment, Icon} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import {codeIntoCoord, uniqueCodeLat, uniqueCodeLon} from '../../utils/coordinates'
import mapStyles from '../../components/Map/MapDisplay/mapStyles.json'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import './edit.css'

const linkStyles = mapStyles['linkStyles']
export default class AddressEdit extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            name: props.location.state.name,
            extra: "",
            errors: {},
            code:'',
            lat:10,
            lng:10,
            zoom:10
          }
          this._onClickMap = this._onClickMap.bind(this)
          this.currentLocation = this.currentLocation.bind(this)
          this.getZoom = this.getZoom.bind(this)
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
    componentDidMount(){
        axios.post('/api/address/name',{
            search:this.state.name
        })
        .then(res=>{
            const getCoord = codeIntoCoord(res.data.code)
            this.setState({extra:res.data.extra,code:res.data.code,lat:getCoord[0],lng:getCoord[1]})
        })
        .catch(err=>this.setState({errors:{'error':'Error while fetching data'}}))
    }
    _onClickMap(map, evt) {       
        const lng = map.latlng.lng.toFixed(6)
        const lat = map.latlng.lat.toFixed(6)
        const newLatCode =  uniqueCodeLat(lat)
        const newLngCode = uniqueCodeLon(lng)
        const newCode = newLatCode+'-'+newLngCode
        this.setState({lat:lat,lng:lng,code:newCode})
    }
    getZoom(map,evt){
        this.setState({zoom:map._zoom})
    }
    onSubmit = e => {
        e.preventDefault();
        axios
        .post('/api/address/edit',{
            'code':this.state.code,
            'extra':this.state.extra,
            'name':this.state.name
        })
        .then(res=>this.props.history.push('/myaddress'))
        .catch(err=>{
            if(err.response){
                this.setState({errors:err.response.data})
            }
            else if(err.request){
                this.setState({errors:err.request})
            }
            else{
                this.setState({errors:'Server Error'})
            }
        })
    };

    currentLocation(){
        navigator.geolocation.getCurrentPosition(
            (coords) => {
                const { longitude, latitude } = coords.coords;
                const newLatCode =  uniqueCodeLat(latitude)
                const newLngCode = uniqueCodeLon(longitude)
                const newCode = newLatCode+'-'+newLngCode
                this.setState({lat:latitude,lng:longitude,code:newCode})
            },
            err => {
                this.setState({
                    error:{'geo':'GeoLocation Permission was denied!!'}
                })
            }
      );
    }


    render(){
        const {lat,lng,code,errors, zoom} = this.state
        return(
            <Grid style={{marginTop:'50px'}} columns={2} stackable>
                <Grid.Column width={10}>
                    <Segment>
                    <Map 
                    center={[lat,lng]}
                    onClick={this._onClickMap}
                    zoom={zoom}
                    onZoom={this.getZoom}
                    >
                        <TileLayer
                            attribution='&copy; OpenStreetMap'
                            url={linkStyles['basic']}
                        />
                        <Button size='large' icon id="refreshButton" onClick={this.currentLocation}>
                            <Icon name="crosshairs" size='big'/>
                        </Button>
                        <Marker
                        position={[lat,lng]}
                        >
                            <Popup>{this.lat},{this.lng}</Popup>
                        </Marker>
                    )}
                    </Map>
                    </Segment>
                </Grid.Column>
                <Grid.Column width={6}>
                    <Segment>
                        <h2>Edit This Address</h2>
                        <Form  noValidate onSubmit={this.onSubmit}>
                        <Form.Field>
                            <label className="active">16 Digit Code</label>
                            <input disabled value={code} name="name"  type="text"/>                                
                        </Form.Field>
                        <Form.Field>
                            <label htmlFor="name">Unique Name</label>
                            <input
                                onChange={this.onChange}
                                value={this.state.name}
                                id="name"
                                type="text"
                                disabled
                            />
                        </Form.Field>
                        <Form.Field>
                            <label htmlFor="disabled">Extra Info</label>
                            <input  
                                onChange={this.onChange}
                                value={this.state.extra}
                                id="extra"
                                type="text"                                    
                            />
                            <span className="red-text">{errors.extra}</span>
                            <span className="helper-text" data-error="wrong" data-success="right">For eg: House 43, Room 401</span>
                        </Form.Field>
                            <Link to='/myaddress'>
                                <Button basic color='red' size='big'>Cancel</Button>
                            </Link>                  
                            <Button basic color='blue' size='big' type='submit' value='submit'>Submit</Button>
                            <br/>
                            <p>{errors.add}</p>
                                              
                    </Form>
                    </Segment>
                </Grid.Column>                
            </Grid>
        )
    }
}