import React from 'react'
import { Segment, Container,Grid, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './footer.css'
export default class Footer extends React.Component{
    render() {
        return(
            <Segment inverted vertical className='footer' style={{backgroundColor:"#41c9f0"}}>
                <Container>
                    <Grid>
                        <Grid.Row columns={3}>
                            <Grid.Column textAlign="center">
                                <Header as="h3" style={{color:"white"}}><Link to='/faq'>FAQs</Link></Header>
                            </Grid.Column>
                            <Grid.Column textAlign="center">
                                <Header as="h3" style={{color:"white"}}>Contact Us</Header>
                            </Grid.Column>
                            <Grid.Column textAlign="center">
                                <Header as="h3" style={{color:"white"}}><Link to='/map'>Map</Link></Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Segment>
        )
    }
}