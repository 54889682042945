import React, {Component} from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'
import {Form, Button, Header, Container ,Message, Card, Segment} from 'semantic-ui-react'
import './style.css'

export default class Login extends Component{
    constructor(props){
        super(props)
        this.state= {
            email:'',
            password:'',
            error:{},
            emailSent:false
        }
        this.onVerificationButton = this.onVerificationButton.bind(this)
    }

    handleInputChange = (event) =>{
        const {value, name} = event.target
        this.setState({[name]:value})
    }

    componentDidMount() {
        axios.get('/checkToken')
        .then(res => {
            if (res.data === "OK") {
                this.props.history.push('/myaddress')
            }
        })
        .catch(err=>{
            this.setState({error:{'login':false}})
        })
    }
    onVerificationButton(){
        axios.post('/api/users/send/verification',{
            'email':this.state.email,
        })
        .then((res)=>{
            window.location.reload()
        })
        .catch((err) => {
            window.location.reload()
        })
    }

    onSubmit = (event) =>{
        event.preventDefault()
        axios.post('/api/users/login',{
            'email':this.state.email,
            'password':this.state.password
        })
        .then(res=>{
            if(res.status === 200){
                window.location.href='./myaddress'
            }
        })
        .catch(err => {
            this.setState({error:{'email':err.response.data.error},password:''});
        });
    }

    render(){
        const {error, emailSent} = this.state
        return(
            <div className="background">
                <Container text>
                    <Segment.Group className="spaceabove">
                        <Segment>
                            <Header as='h3'>Login</Header>
                        </Segment>
                        <Segment>
                            <Form onSubmit={this.onSubmit}>
                                <Form.Field>
                                <label>Email Address</label>
                                <input 
                                    type="email"
                                    name="email"
                                    placeholder="Enter email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange}
                                    required
                                />
                                </Form.Field>
                                <Form.Field>
                                <label>Password</label>
                                <input 
                                    type="password"
                                    name="password"
                                    placeholder="Enter password"
                                    value={this.state.password}
                                    onChange={this.handleInputChange}
                                    required
                                />
                                </Form.Field>
                                <Button basic type='submit' color='green'>Submit</Button>                
                            </Form>
                                {error.hasOwnProperty('email') ? 
                                        <Message negative>
                                            <Message.Header>{error.email}</Message.Header>
                                            {error.email === 'Please verify your email address' && !emailSent ?
                                                <Button content='Send new verification email?' onClick={this.onVerificationButton} primary />
                                                :
                                                <div></div>
                                            }
                                        </Message>
                                        : <div></div>
                                }
                            <br />
                            <small><Link to="/account/reset">Forgot your password?</Link></small>
                        </Segment>
                        <Segment>
                            <Card>
                                <Card.Content description={"Don't have an account?"} />
                                <Card.Content extra>
                                    Click here to <Link to='/register'>Register</Link>
                                </Card.Content>
                            </Card>
                        </Segment>
                    </Segment.Group>
                </Container>
            </div>
        )
    }
}