import React from 'react'
import axios from 'axios'
import {Grid, Form, Button, Segment, Header, Icon} from 'semantic-ui-react'
import './address.css'
import {Link} from 'react-router-dom'
import {codeIntoCoord} from '../../utils/coordinates'
import mapStyles from '../../components/Map/MapDisplay/mapStyles.json'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'

const linkStyles = mapStyles['linkStyles']
export default class AddressRegister extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            name: "",
            extra: "",
            errors: {},
            code:props.location.state,
            availability:true
          }
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    componentDidMount(){
        axios.post('/api/address/view',{'api':'getdata'})
        .then(res=>{
            if(res.data === 'No documents found'){
                this.setState({availability:true})
            }
            else if(res.data !== 'No documents found' && res.data.length >= 5){
                this.setState({availability:false})
            }
        })
        .catch(err=>{this.setState({errors:{'data':'failed to fetch'}})})
    }
    onSubmit = e => {
        e.preventDefault();
        axios
        .post('/api/address/create',{
            'name':this.state.name,
            'code':this.state.code.code,
            'extra':this.state.extra
        })
        .then(res=>this.props.history.push('/myaddress'))
        .catch(err=>{
            if(err.response){
                this.setState({errors:err.response.data})
            }
            else if(err.request){
                this.setState({errors:err.request})
            }
            else{
                this.setState({errors:'Server Error'})
            }
        })
    };
    render(){
        const {code,errors,availability} = this.state
        const lat = codeIntoCoord(code.code)[0]
        const lng = codeIntoCoord(code.code)[1]
        return(
            <Grid style={{marginTop:'50px'}} columns={2} stackable>                
                <Grid.Column width={10}>
                    <Segment>
                    <Map 
                    center={[lat,lng]}
                    zoom={[10]}
                    >
                        <TileLayer
                            attribution='&copy; OpenStreetMap'
                            url={linkStyles['basic']}
                        />
                        <Marker
                        position={[lat,lng]}
                        >
                            <Popup>{this.state.code.code}</Popup>
                        </Marker>
                    )}
                    </Map>
                    </Segment>
                </Grid.Column>  
                    <Grid.Column width={6}>
                        <Segment>
                        {availability ?
                        <Form  noValidate onSubmit={this.onSubmit}>
                        <h2>Register This Address  |
                            <Link to='/map'>
                                Change Address
                            </Link>
                        </h2>
                        <Form.Field>
                            <label className="active">16 Digit Code</label>
                            <input disabled value={code.code}  type="text"/>                                
                        </Form.Field>
                        <Form.Field>
                            <label htmlFor="name">Unique Name</label>
                            <input
                                onChange={this.onChange}
                                value={this.state.name}
                                id="name"
                                type="text"
                            />                            
                            <span className="red-text">{errors.name}</span>
                        </Form.Field>
                        <Form.Field>
                            <label htmlFor="disabled">Extra Info</label>
                            <input  
                                onChange={this.onChange}
                                value={this.state.extra}
                                id="extra"
                                type="text"                                    
                            />
                            <span className="red-text">{errors.extra}</span>
                            <span className="helper-text" data-error="wrong" data-success="right">For eg: House 43, Room 401</span>
                        </Form.Field>    
                            <Button basic color='blue' size='big' type='submit' value='submit'>Submit</Button>
                            </Form>
                            :
                            <div>
                                <Header as='h3' color='red'><Icon name='window close'></Icon> Sorry, you have already used your 5/5 addresses.</Header>
                                <Link to='/myaddress'><Button basic color='blue' size='big' >Manage Address</Button></Link>
                            </div>
                            
                            }
                            <br/>
                            <p>{errors.add}</p>
                                              
                    
                    </Segment>
                </Grid.Column>                
            </Grid>
        )
    }
}