import React from 'react'
import { Input } from 'semantic-ui-react'
import axios from 'axios'
import './mapsearch.css'
import {codeIntoCoord} from '../../../utils/coordinates'

export default class MapSearch extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            error:'',

        }
        this.onChangeName = this.onChangeName.bind(this)
    }
    onChangeName = e =>{
        if(e.key === 'Enter'){
            const inputName = e.target.value
            if(inputName.match("^[0-9]{4}-{0,1}[0-9]{4}-{0,1}[0-9]{4}-{0,1}[0-9]{4}$")){
                const getCoords = codeIntoCoord(inputName)
                const newLat = getCoords[0]
                const newLng = getCoords[1]
                this.props.onSearchChange(newLat, newLng)
            }
            else if(inputName.match("^[a-zA-Z0-9]*$")){
                axios
                    .post('/api/address/name',{
                        'search':inputName
                    })
                    .then(res=>{
                        const getCoords = codeIntoCoord(res.data.code)
                        const newLat = getCoords[0]
                        const newLng = getCoords[1]
                        this.setState({
                            error:'',
                        })
                        this.props.onSearchChange(newLat, newLng,res.data.name,res.data.extra)
                    })
                    .catch(err=>this.setState({error:'Sorry couldnot find that address'}))
            }
            else{
                this.setState({error:"Invalid Format"})
            }
        }
    }
    render(){
        const {error} = this.state
        return(
            <div className='search-box'>
                <Input icon='search' fluid placeholder='1234-5678-9101-1121 or Enter Unique Name'  size='large' focus onKeyPress={this.onChangeName}/>
                <p style={{color:'red'}}>{error}</p>
            </div>        
        )
    }
    
}