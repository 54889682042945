import React from 'react'
import { Grid, Divider, Segment } from 'semantic-ui-react';
import {withRouter} from 'react-router-dom';
import {uniqueCodeLat, uniqueCodeLon} from '../../utils/coordinates'
import MapSearch from '../../components/Map/MapSearch/MapSearch'
import MapDisplay from '../../components/Map/MapDisplay/MapDisplay'
import MapLayout from '../../components/Map/MapLayout/MapLayout'
import MapDetail from '../../components/Map/MapDetail/MapDetail'
import './map.css'

class MapHome extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            lat: 28,
            lng: 84,
            code : '',
            style:'basic',
            name:'',
            extra:''
        }
        this.onMapClickChange = this.onMapClickChange.bind(this)
        this.changeLayout = this.changeLayout.bind(this)
        this.onSearchChange = this.onSearchChange.bind(this)
    }
    uniqueCode(){
        const getLatCode = uniqueCodeLat(this.state.lat)
        const getLngCode = uniqueCodeLon(this.state.lng)
        return getLatCode+'-'+getLngCode
    }

    onMapClickChange(newlat,newlng){
        this.setState({
            lat:newlat,
            lng:newlng,
            name:'',
            extra:''
        })
    }
    changeLayout(style){
        if(this.state.style !== style)
        this.setState({
            style:style
        })
    }
    onSearchChange(newlat, newlng, name, extra){
        this.setState({
            lat:newlat,
            lng:newlng,
            name:name,
            extra:extra
        })
    }

    render(){
        const {lat,lng, style, name, extra} = this.state
        const code = this.uniqueCode()
        return(
            <div className='maplayout'>
                <div className='map-search'>
                    <Grid columns={1} stackable>
                        <Grid.Column>
                            <MapSearch onSearchChange={this.onSearchChange}/>
                        </Grid.Column>
                    </Grid>                    
                </div>
                <div className='maplayer' >
                <Grid columns={2} stackable>
                    
                        <Grid.Column width={12}>
                        <Segment>
                            <MapDisplay lat={lat} lng={lng} style={style} onMapClickChange={this.onMapClickChange}/>
                            </Segment>
                        </Grid.Column>
                    
                        <Grid.Column width={4}>
                            <Segment>
                            <MapLayout changeLayout={this.changeLayout}/>
                            <Divider/>
                            <MapDetail uniqueCode={code} name={name} extra={extra}/>
                            </Segment>
                        </Grid.Column>
                        
                    </Grid>
                </div>                
            </div>

            
        )
    }
}

export default withRouter(MapHome)