import React from 'react'
import {Form, Button, Header, Container, Segment, Dimmer, Loader, Card} from 'semantic-ui-react'
import { Link } from "react-router-dom";
import axios from 'axios'
import './style.css'

export default class Register extends React.Component{
    constructor(props){
        super(props)
        this.state= {
            email:'',
            password:'',
            password2:'',
            errors:{},
            isLoading:false
        }
    }
    handleInputChange = (event) =>{
        const {value, name} = event.target
        this.setState({[name]:value})
    }
    componentDidMount() {
        axios.get('/checkToken')
        .then(res => {
            if (res.data === "OK") {
                this.props.history.push('/myaddress')
            }
        })
        .catch(err=>{
            this.setState({error:{'login':false}})
        })
    }
    onSubmit = (event) =>{
        this.setState({isLoading:true})
        event.preventDefault()
        axios.post('/api/users/register',{
            'name':this.state.email.split('@')[0],
            'email':this.state.email,
            'password':this.state.password,
            'password2':this.state.password2
        })
        .then(res=>{
            this.setState({isLoading:false})
            if(res.status === 200){
                this.props.history.push('/login')
            } else{
                const error = new Error(res.error);
                throw error;
            }
        })
        .catch(err => {
            if(err.response){
                this.setState({errors:err.response.data, isLoading:false})
            }
            else if(err.request){
                this.setState({errors:err.request, isLoading:false})
            }
            else{
                this.setState({errors:'Server Error', isLoading:false})
            }
        });
    }
    render(){
        const {errors, isLoading} = this.state
        if(isLoading){
            return(
            <Segment style={{marginTop:'60px'}}>
                <Dimmer active inverted>
                <Loader inverted>Registering...</Loader>
                </Dimmer>
            </Segment>
            )
        }
        return(
            <div className="background">
                <Container text>
                    <Segment.Group className="spaceabove">
                        <Segment>
                            <Header as='h3'>Register</Header>
                        </Segment>
                        <Segment>
                            <Form onSubmit={this.onSubmit}>
                                <Form.Field>
                                <label>Email Address</label>
                                <input 
                                    type="email"
                                    name="email"
                                    placeholder="Enter email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange}
                                    required
                                />
                                <span style={{color:"red"}}>{errors.email}</span>
                                </Form.Field>
                                <Form.Field>
                                <label>Password</label>
                                <input 
                                    type="password"
                                    name="password"
                                    placeholder="Enter password"
                                    value={this.state.password}
                                    onChange={this.handleInputChange}
                                    required
                                />
                                </Form.Field>
                                <Form.Field>
                                <label>Confirm Password</label>
                                <input 
                                    type="password"
                                    name="password2"
                                    placeholder="Enter password again"
                                    value={this.state.password2}
                                    onChange={this.handleInputChange}
                                    required
                                />
                                <span className="red-text">{errors.password2}</span>
                                </Form.Field>
                                <Button basic type='submit' color='green'>Register</Button>
                            </Form>
                        </Segment>
                        <Segment>
                            <Card>
                                <Card.Content description={"Already have an account?"} />
                                <Card.Content extra>
                                    Click here to <Link to='/login' color='blue'>Login</Link>
                                </Card.Content>
                            </Card>
                        </Segment>
                    </Segment.Group>
                </Container>
            </div>
        )
    }
}