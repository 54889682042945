import React , {Component} from 'react';
import {BrowserRouter as Router,  Route, Switch} from 'react-router-dom'

import Navbar from './containers/Navbar/Navbar'
import Home from './components/Home/Home'
import Footer from './containers/Footer/Footer'
import Login from './components/Login'
import Register from './components/Register'
import withAuth from './components/auth'
import  Dashboard from './components/Dashboard'
import MapHome from './containers/Map/Map'
import AddressRegister from './containers/AddressRegister/AddressRegister'
import EditAddress from './containers/EditAddress/EditAddress'
import EmailVerify from './components/EmailVerify'
import ResetPass from './components/ResetPass'
import ChangePassword from './components/ChangePassword'
import FAQ from './pages/FAQ'

export default class App extends Component {
  render(){
    return (
      <Router>
        <div>            
            <Navbar login={true}/>            
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/login" component={ Login } />
                <Route path="/register" component={Register} />
                <Route path="/map" component={MapHome} />
                <Route path="/myaddress" component={withAuth(Dashboard)} />
                <Route path="/add" component={withAuth(AddressRegister)} />
                <Route path="/edit" component={withAuth(EditAddress)} />
                <Route path="/email/verify/:email/:token" component={EmailVerify} />
                <Route path="/account/reset" component={ResetPass} />
                <Route path="/account/password/new/:email/:token" component={ChangePassword} />
                <Route path="/faq" component={FAQ} />
              </Switch>
            <Footer />
          </div>
      </Router>
    )
  }
}
