export function uniqueCodeLat(lat) {
    let change_lat = lat.toString().replace('.','')
    if (change_lat.substring(0,1) === "-"){
        change_lat = change_lat.replace('-','1')
    }
    else{
        change_lat = '0'+change_lat
    }        
    if(Math.abs(lat)<10)
    {
        change_lat = change_lat.substring(0,1)+'0'+ change_lat.substring(1,change_lat.length)
    }           
    if(change_lat.length >= 8)
    {
        change_lat = change_lat.substring(0,8)
    }
    else if(change_lat.length < 8)
    {
        change_lat =  change_lat.padEnd(8,0)
    }
    change_lat = change_lat.substring(0,4)+'-'+change_lat.substring(4,change_lat.length)
    return change_lat
}

export function uniqueCodeLon(lng) {

    if(lng < 0 ){
        lng = 180 + Math.abs(lng)
    }
    let change_lon = lng.toString().replace('.','')
    change_lon = change_lon.replace('-','')
    
    if(Math.abs(lng) < 100){
        change_lon = '0'+change_lon
        if(Math.abs(lng)< 10){
            change_lon = '0'+change_lon
        }
    }
    
    if(change_lon.length > 8){
        change_lon= change_lon.substring(0,8)
    }
    else if(change_lon.length < 8){
        change_lon = change_lon.padEnd(8,0)
    }
    change_lon = change_lon.substring(0,4)+'-'+change_lon.substring(4,change_lon.length)
    return change_lon
}

export function codeIntoCoord(code){
    let get_code = code.replace(/-/g,'')

    let c1 = get_code.substring(0,3)+'.'+get_code.substring(3,8)
    let c2 = get_code.substring(8,11)+'.'+get_code.substring(11,16)

    if(c1.substring(0,1) === '1'){
        c1 = '-'+c1.substring(1,c1.length)
    }
    c2 = parseFloat(c2)
    if(c2 > 180){
        c2  = -1 * (c2-180)
    }
    const coord = [parseFloat(c1),parseFloat(c2)]
    return coord
}
