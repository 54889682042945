import React from 'react'
import { Container,Grid, Header } from 'semantic-ui-react'
export default class FAQ extends React.Component{
    render() {
        return(
            <div className="home">
            <Container>
            <Grid style={{marginTop:'50px'}} >
            <Grid.Row>
                <Grid.Column width={16}>
                    <Header as='h2' className="heading">How does it work?</Header>
<p className="abtFont">Pick a point in the map, register by giving it your preferred name that is it. The preferred name can be your email address or any word or number you like. Once registered, it is your unique address for life. 

Technical explanation is we take co-ordinates of any point and convert those co-ordinates into simple 16 digits format for unregistered use. 16 digits are difficult to remember, so we give additional functionality to give it a unique nickname. When our database is searched for the nickname, it finds the 16 digits for that nickname and then the co-ordinates and shows it in the map. Rest is the simple stuff.  
</p>

<Header as='h2' className="heading">Who are behind this?</Header>
<p className="abtFont">
There is no conspiracy! Bayupayu is being developed by ordinary developers after being frustrated with the lack of addressing system in the most of the world. We invented nothing new or radical. We just made simple tweaks to add functionality to the existing latitude and longitude system. 
</p>

<Header as='h2' className="heading">Why 16 digits? </Header>
<p className="abtFont">
16 digits were selected to give the precision of 1.21 M by 1.21 M. We think that is adequate accuracy. 16 digit is a familiar number sequence to most because of credit and debit cards. The numbers can be conveyed over phone or written or shared via email, messaging services. 
</p>
<Header as='h2' className="heading">What are the advantages?</Header>
<p className="abtFont">Every point all over the world has an address not just your house. Imagine asking your friend to come where you are in the central train station or that picnic spot in the park or the gate in the stadium. Imagine your car breaks down on a freeway and you need to ask for help. Now just look at your phone and tell your 16 digits so that people can locate you. It helps us communicate locations conveniently and accurately for everyday events or those emergencies when we need them most. 
For the city dwellers with perfect address. This helps to make it easy. Instead of saying I live in Flat 99, Building 100, 77 Eighty Eight Street, New Town, New South Wales, 2600, Australia I can say I live at ‘Alpha’ or whatever I call it to be. You can see the benefit but not much right! We agree. Now imagine I move to other parts of the country another address. The first few weeks of my move will be spent on contacting everyone that I have moved and this is my address. However, with this system I can simply log in and point my address Alpha to a new address. After that, everyone and everything automatically reroutes to my new address. 
And for those who live in places without city infrastructure, there is no address. For them, this service provides immediate address to add convenience to their lives and businesses.   
</p>
<Header as='h2' className="heading">How is this different to What3Words or Plus Codes? </Header>
<p className="abtFont">Both What3Words and Plus Codes are great alternate addressing system. What3Words uses words which is not neutral and beyond populated areas the word combinations starts to lose practicality because of plurals and past tense etc. Number are neutral and they never translate wrong in any place for worldwide rollout. We went ahead with numbers. 
Plus Codes are amazing and there is a lot of logic. But they are difficult to remember. Everyone need easy to remember addressing system. We may be wrong but we think the most easy to remember address is what you pick for yourself like your email address, a favorite word or whatever you like. 
We suggest you try both our system and other alternate systems, and you should see the difference. However, if not you can send us your feedback. 
</p>
<Header as='h2' className="heading">Any future plans?</Header>
<p className="abtFont">This is an idea that has to be loved by you. If the idea is well loved, we will add the functionality and keep developing the software. We think we tried our best and that may inspire someone somewhere in the world to develop the concept of alternate addressing system further. 
If you are a humanitarian organization or a business venture that you think will benefit from this, we will love to hear from you and can expedite the development. 
</p>
<Header as='h2' className="heading">Will systems like these ever replace the traditional street system?</Header>
<p className="abtFont" style={{marginBottom:'50px'}}>
No. Never. They won’t. They can’t. The physical infrastructure like street
signs, government authority and control etc. makes the traditional
systems very robust. How much we wish, systems like bayupayu add
extra value to the traditional system but they can never replace them.

Is it closed or proprietary system? Will you open it for all?
What we have done anyone can do in a matter of days, so this is nothing
like a top secret. WeIf you think opening this up will help your project or
anyone stay in touch and we can work jointly to get it released to the public. 
</p>
            </Grid.Column>
        </Grid.Row>
        </Grid>
        </Container>
        </div>
        )
    }
}