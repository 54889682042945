import React from 'react'
import {Form, Button, Header, Container, Segment, Dimmer, Loader} from 'semantic-ui-react'
import { Link } from "react-router-dom";
import axios from 'axios'
import './style.css'

export default class ResetPass extends React.Component{
    constructor(props){
        super(props)
        this.state= {
            email:'',
            errors:{},
            isLoading:false
        }
    }
    handleInputChange = (event) =>{
        const {value, name} = event.target
        this.setState({[name]:value})
    }
    componentDidMount() {
        axios.get('/checkToken')
        .then(res => {
            if (res.data === "OK") {
                this.props.history.push('/myaddress')
            }
        })
        .catch(err=>{
            this.setState({error:{'login':false}})
        })
    }
    onSubmit = (event) =>{
        this.setState({isLoading:true})
        event.preventDefault()
        if(this.validateEmail(this.state.email)){
            axios.post('/api/users/reset/password',{
                'email':this.state.email,
            })
            .then(res=>{
                this.setState({isLoading:false})
                if(res.status === 200){
                    this.props.history.push('/login')
                } else{
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .catch(err => {
               this.setState({errors:err.response.data, isLoading:false})
            });
        } else {
            this.setState({errors:{'error':'Invalid Email type'}, isLoading:false})
        }
    }

    validateEmail(email) {
        var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return re.test(String(email).toLowerCase());
    }

    render(){
        const {errors, isLoading} = this.state
        if(isLoading){
            return(
            <Segment style={{marginTop:'60px'}}>
                <Dimmer active inverted>
                <Loader inverted>Loading...</Loader>
                </Dimmer>
            </Segment>
            )
        }
        return(
            <div className="background">
                <Container text>
                    <Segment.Group className="spaceabove">
                        <Segment>
                            <Header as='h3'>Reset your password</Header>
                        </Segment>
                        <Segment>
                            <Form onSubmit={this.onSubmit}>
                                <Form.Field>
                                <label>Email Address</label>
                                <input 
                                    type="email"
                                    name="email"
                                    placeholder="Enter email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange}
                                    required
                                />
                                <span style={{color:"red"}}>{errors.error}</span>
                                </Form.Field>
                                <Button basic type='submit' color='green'>Send Reset Link</Button>
                                <p>Once submitted, please check your email inbox or spam folder. Follow the instructions to reset the password.</p>
                            </Form>
                        </Segment>
                        <Segment>                            
                            Back to <Link to='/login' color='blue'>Login</Link>                            
                        </Segment>
                    </Segment.Group>
                </Container>
            </div>
        )
    }
}