import React, {Component} from 'react'
import {Grid, Button} from 'semantic-ui-react'
import './home.css'
import { Link} from 'react-router-dom'
import android from '../../assets/img/google.png'
import apple from '../../assets/img/apple.png'

export default class Home extends Component {   
  
    render() {
      return (
        <Grid className='home' >
          <Grid.Row centered columns={2} style={{marginTop:'80px'}}>
            <Grid.Column >
              
                <div className="resp-container">
                  <iframe title="video" src="https://player.vimeo.com/video/73999574" className="resp-iframe" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </div>                
              
            </Grid.Column>
          </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                <div className='button'>
                    <Link to='/map'><Button className='map-button' size='massive'>View Map</Button></Link>
                </div>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8} textAlign="right">
                <a href='https://www.google.com'><img src={android} width="200px" alt="google" /></a>
              </Grid.Column>
              <Grid.Column width={8} textAlign="left">
                <a href='https://www.apple.com'><img src={apple} width="170px" alt="apple" style={{marginTop:'10px'}} /></a>
              </Grid.Column>
            </Grid.Row>
        </Grid>
      );
    }
  
  }