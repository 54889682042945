import React from 'react'
import { Card, Button, Modal, Header, Image, Icon} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import {codeIntoCoord} from '../../../utils/coordinates'
export default function MapDetail(props){
    const coordinates = codeIntoCoord(props.uniqueCode)
    const googlemap = "https://www.google.com/maps?daddr="+coordinates
    const whatsapp = 'https://wa.me/?text=Hey My BayuPayu Code is '+props.uniqueCode+'. Check the google map:'+ googlemap
    return(
        <div>
            <Card fluid style={{marginBottom:'60px'}}>
                <Card.Content>
                    <Card.Header as='h2'>16 digit Code : {props.uniqueCode}</Card.Header>
                    <Card.Description as='h3'>
                        <p>{props.name}</p>
                        <small>{props.extra}</small>
                    </Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <div className='ui two buttons'>
                    <Link to={{ pathname:'/add',state:{code:props.uniqueCode} }}>
                        <Button basic color='green'>
                            Register this Address
                        </Button>
                    </Link>
                    <Modal trigger={<Button basic color='blue'>
                        Share this Address
                    </Button>}>
                        <Modal.Header>Share the Address</Modal.Header>
                        <Modal.Content image>
                        <Image 
                            wrapped 
                            size='medium' 
                            src={"https://api.qrserver.com/v1/create-qr-code/?color=000&bgcolor=FFFFFF&data="+googlemap+"&qzone=1&margin=0&size=150x150&ecc=L"} />
                        <Modal.Description>
                            <Header>Unique Code:{props.uniqueCode}</Header>
                            <p><a href={googlemap} target='_blank' without="true" rel="noopener noreferrer"><Icon name='location arrow'></Icon>Get Direction</a></p>
                            <p><a href={whatsapp} target='_blank' without="true" rel="noopener noreferrer">Share On<Icon name='whatsapp' size='big' color='green'></Icon></a></p>
                            <p><a href={"https://www.facebook.com/sharer/sharer.php?u="+window.location.protocol +"//"+window.location.hostname+"/sixteencode="+props.uniqueCode} target='_blank' without="true" rel="noopener noreferrer">Share On<Icon name='facebook' size='big' color='blue'></Icon></a></p>
                        </Modal.Description>
                        </Modal.Content>
                    </Modal>
                    
                    
                    </div>
                </Card.Content>
            </Card>
        </div>     
    )
}