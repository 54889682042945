import React, {Component} from 'react';
import {Menu, Icon,Dropdown} from 'semantic-ui-react'
import {Link, withRouter} from 'react-router-dom'
import axios from 'axios'
import './navbar.css'
import icon from '../../assets/img/Icon.png'
import title from '../../assets/img/bayupayu_transparent.png'
  const trigger = (
    <span>
      <Icon  name='user circle' size='big' style={{color:"#41c9f0" }}/>
    </span>
  )

class NavBar extends Component {
    constructor(props){
        super(props)
        this.state ={
            login : false
        }
        this.logoutUser = this.logoutUser.bind(this)
    }
    

    componentDidMount() {
        axios.get('/checkToken')
        .then(res => {
            if (res.data === "OK") {
                this.setState({
                    login:true
                    })
            }
            else{
                this.setState({
                    login:false
                    })
            }
        })
        .catch(err=>{
            this.setState({
                login:false
                })
        })
    }

    logoutUser(event){
        event.preventDefault()
        axios.post('/api/users/logout',{'api':'logout'})
        .then(()=>{
            this.setState({
                login:false
            })
            this.props.history.push('/');
        })
      }
    render(){
        const {login} = this.state
        return(
            <Menu borderless className='top-menu'>
                <Menu.Menu>
                    <Menu.Item>
                        <img src={icon} alt="logo" />
                    </Menu.Item>
                    <Menu.Item>
                        <Link to='/'><img src={title} width="200px" alt="title" /></Link>
                    </Menu.Item>
                </Menu.Menu>                
                <Menu.Menu position='right'>
                    <Menu.Item name='avatar'>
                        <Dropdown trigger={trigger}>                        
                            {login ?
                            <Dropdown.Menu>
                                <Link to='/myaddress'>
                                    <Dropdown.Item>
                                        My Address
                                    </Dropdown.Item>
                                </Link>
                                <span onClick={this.logoutUser}>
                                    <Dropdown.Item>
                                        Logout
                                    </Dropdown.Item>
                                </span>
                                </Dropdown.Menu>
                                :
                                <Dropdown.Menu>
                                <Link to='/login'>                                
                                    <Dropdown.Item>
                                        Login
                                    </Dropdown.Item>
                                </Link>
                            </Dropdown.Menu>                            
                            }
                        </Dropdown>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
        )
    }
}
export default withRouter(NavBar);
